import { axios } from './axios';

export const fetchContractAwards = async (params) => {
    return await axios.get('/contract-award/list', { params: params });
};

export const insertContractAward = async (body) => {
    return await axios.post('/contract-award', body);
};

export const updateContractAward = async (body) => {
    return await axios.put('/contract-award', body);
};

export const deleteContractAward = async (id) => {
    return await axios.delete('/contract-award?_id=' + id);
};
